import React from 'react';
import { LocationTypes } from '../../constants';
import * as Styled from './locationMarkerStyle';
import { useTranslation } from 'react-i18next';
import { addTrailingSlash, isExternalUrlHref, isValidURLLanguage } from '../../utils';
import { useLocation } from '@reach/router';

const LocationMarker = (props) => {
  const [translation] = useTranslation();
  let phones;
  const offerings = [
    {
      boolean: props?.node?.hasHeatingOil,
      name: translation('HeatingOil'),
    },
    {
      boolean: props?.node?.hasDiesel,
      name: translation('Diesel'),
    },
    {
      boolean: props?.node?.hasMarkedDiesel,
      name: translation('MarkedDiesel'),
    },
    {
      boolean: props?.node?.hasGasoline,
      name: translation('Gasoline'),
    },
    {
      boolean: props?.node?.hasPropane,
      name: translation('Propane'),
    },
    {
      boolean: props?.node?.hasLubricants,
      name: translation('Lubricants'),
    },
    {
      boolean: props?.node?.hasHeatingEquipment,
      name: translation('HeatingEquipment'),
    },
    {
      boolean: props?.node?.hasDef,
      name: translation('Def'),
    },
    {
      boolean: props?.node?.hasShowers,
      name: translation('Showers'),
    },
    {
      boolean: props?.node?.hasRestrooms,
      name: translation('Restroom'),
    },
    {
      boolean: props?.node?.hasWifi,
      name: translation('Wi-Fi'),
    },
    {
      boolean: props?.node?.hasParking,
      name: translation('Parking'),
    },
    {
      boolean: props?.node?.hasRestaurant,
      name: translation('Restaurant'),
    },
    {
      boolean: props?.node?.hasConvenienceStore,
      name: translation('ConvenienceStore'),
    },
    {
      boolean: props?.node?.hasLunchCounter,
      name: translation('LunchCounter'),
    },
    {
      boolean: props?.node?.hasDriversLounge,
      name: translation('DriversLounge'),
    },
    {
      boolean: props?.node?.hasMultiServices,
      name: translation('MultiServices'),
    },
  ];

  const StringGenerator = (object) => {
    let header = '';
    let length = 0;
    object.map((item) => {
      if (item.boolean) {
        if (length !== 0) {
          header += ', ' + item.name;
          length++;
        } else {
          header += item.name;
          length++;
        }
      }
    });
    return header;
  };
  const [showPopup, setShowPopup] = React.useState(false);
  const handleClick = () => {
    setShowPopup(true);
    props.onMarkerClick(props);
  };

  const markerStyle = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    height: '50px',
    transform: 'translate(-50%, -100%)',
  };

  const location = useLocation();
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);
  const appendLanguageToURL = isValidURLLanguage(urlLanguage) && urlLanguage;

  return (
    <div>
      {showPopup && props.id === props.currentMarker && (
        <Styled.MainDiv>
          <Styled.InnerDiv>
            <Styled.PopupCloseStyle
              onClick={() => {
                setShowPopup(false);
              }}
            >
              x
            </Styled.PopupCloseStyle>
            <Styled.InfoWindowStyle>
              <Styled.InfoWindowLeft>
                <Styled.InfoWindowH2>
                  {props?.node?.location}
                </Styled.InfoWindowH2>
                <Styled.ParaText>
                  <Styled.InfoWindowEm>
                    {props?.node?.pinType[0].type ===
                    LocationTypes.PipelineCardlock
                      ? 'Pipeline'
                      : 'Ultramar'}
                  </Styled.InfoWindowEm>
                </Styled.ParaText>
                <Styled.ParaText>
                  {props?.node?.address1}
                  <br />
                  {props?.node?.city + ', ' + props?.node?.province}
                  <br />
                  {props?.node?.postalCode}
                </Styled.ParaText>
                {
                  ((phones = props?.node?.phone.split(' ')),
                  phones.map((phone, index) => (
                    <Styled.AnchorStyle
                      key={index}
                      href={`tel:${phone}`}
                      tabindex="0"
                    >
                      {phone}
                    </Styled.AnchorStyle>
                  )))
                }
              </Styled.InfoWindowLeft>
              <Styled.InfoWindowRight>
                <Styled.InfoWindowH3>
                  {translation('Hours')}
                </Styled.InfoWindowH3>
                <Styled.ParaText>
                  {(props?.node?.pinType.length > 1 ||
                    (props?.node?.pinType.length === 1 &&
                      props?.node?.pinType[0].type !==
                        LocationTypes.UltramarCardlock &&
                      props?.node?.pinType[0].type !==
                        LocationTypes.PipelineCardlock)) && (
                    <>
                      {translation('Branch')}: {props?.node?.branchHours}
                      <br />
                    </>
                  )}

                  {props?.node?.cardlockHours && (
                    <>
                      {translation('Cardlock')}: {props?.node?.cardlockHours}
                    </>
                  )}
                </Styled.ParaText>
                <Styled.InfoWindowH3>
                  {translation('Offerings')}
                </Styled.InfoWindowH3>
                <Styled.ParaText>{StringGenerator(offerings)}</Styled.ParaText>
                {props.showServices && (
                  <Styled.LinkStyle
                    to={
                      isExternalUrlHref(props?.node?.urlSlug)
                        ? props?.node?.urlSlug
                        : addTrailingSlash(process.env.GATSBY_DOMAIN_URL +
                          (appendLanguageToURL === 'fr'
                            ? '/fr/sites/details/'
                            : '/en/locations/details/') +
                          props?.node?.urlSlug)
                    }
                  >
                    {translation('ViewServiceAreas')}
                  </Styled.LinkStyle>
                )}
              </Styled.InfoWindowRight>
            </Styled.InfoWindowStyle>
          </Styled.InnerDiv>
        </Styled.MainDiv>
      )}
      <img style={markerStyle} src={props.src} onClick={handleClick} />
    </div>
  );
};

export default LocationMarker;
